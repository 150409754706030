import { ApolloClient, ApolloProvider, gql, NormalizedCacheObject } from '@apollo/client';
import MomentUtils from '@date-io/moment';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Sentry from '@sentry/browser';
import { hasPermission } from 'components/Can';
import { Employee } from 'generated/schema-types';
import moment from 'moment';
import { NextPageContext } from 'next';
import { AppContext, AppProps } from 'next/app';
import { destroyCookie, parseCookies } from 'nookies';
import { Action } from 'rbac-rules';
import React from 'react';
import 'react-notifications-component/dist/theme.css';
import createApolloClient from 'utils/apolloClient';
import { ModalProvider } from '../context/ModalContext';
import { NotificationContainer, NotificationProvider } from '../context/NotificationContext';
import { UserProvider } from '../context/UserContext';
import '../styles/global.css';
import redirectTo from '../utils/redirectTo';
import withApollo from '../utils/withApollo';
import NotFoundPage from './404';

moment.locale('es-es');

Sentry.init({
  dsn: 'https://73bcca8b97ee423799c2c8f5ce6e77a0@o361946.ingest.sentry.io/4504647265550336',
  release: `${process.env.npm_package_name}@${process.env.npm_package_version}`,
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.NODE_ENV
});

const VERIFY_MUTATION = gql`
  mutation ValidateToken($token: String!) {
    verify(token: $token) {
      username
      id
    }
  }
`;

const GET_USER_QUERY = gql`
  query getEmployeeFromUser($username: String!) {
    employees(where: { username: { equals: $username } }) {
      id
      createdAt
      updatedAt
      name
      nameWithTitle
      username
      photo
      role
      chatParticipant {
        id
      }
      branches {
        id
        name
        phoneNumber
        holderRfc
        chatParticipant {
          id
        }
        address {
          id
          street
          number
          neighborhood
          zipCode
          city
          state
          country
        }
      }
      address {
        id
        street
        number
        neighborhood
        number
        innerNumber
      }
    }
  }
`;

export type AppPageContext = NextPageContext & {
  user: Employee;
};

export type PageProps = Partial<{
  user: Employee;
  permissions: Action[];
}>;

type CustomAppProps = AppProps & {
  apollo: ApolloClient<NormalizedCacheObject>;
  token: string;
  user: Employee;
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#02B9CF',
      contrastText: '#fff'
    },
    secondary: {
      main: '#ffb04c'
    }
  }
});

class AyzerDental extends React.Component<CustomAppProps> {
  static async getInitialProps({ Component, ctx }: AppContext): Promise<Partial<unknown>> {
    if (ctx.pathname === '/_error') return { token: null };

    let pageProps = {};
    const { token } = parseCookies(ctx);
    if (typeof token === 'undefined') {
      if (ctx.pathname !== '/login') {
        // any other page but login
        const path = ctx.pathname === '/' ? '/login' : '/login?redirect_to=' + ctx.asPath;
        redirectTo(path, {
          res: ctx.res,
          status: 302
        });
      }
      return { pageProps };
    }

    let user: Employee | null = null;
    try {
      const apolloClient = createApolloClient(ctx, {});
      const { data } = await apolloClient.mutate({
        mutation: VERIFY_MUTATION,
        variables: {
          token
        }
      });

      const apolloData = await apolloClient.query({
        query: GET_USER_QUERY,
        variables: {
          username: data.verify.username
        }
      });

      if (apolloData.data.employees.length === 0) {
        throw Error('Employee not found');
      }

      user = apolloData.data.employees[0];

      // Throw error if token was invalid
      if (data.verify === null) {
        throw Error('');
      }

      if (ctx.pathname === '/login') {
        const path = typeof ctx.query.redirect_to === 'undefined' ? '/' : ctx.query.redirect_to;
        redirectTo(path as string, { res: ctx.res, status: 302 });
        return {};
      }
    } catch (e) {
      console.log(e);
      destroyCookie(ctx, 'token'); // Remove invalid token
      Sentry.captureException(e);
      redirectTo('/login', { res: ctx.res, status: 302 });
    }

    if (Component.getInitialProps) {
      const otherProps = await Component.getInitialProps({ ...ctx, user } as never);
      pageProps = { ...pageProps, ...otherProps };
    }

    return { pageProps, token, user };
  }

  render(): JSX.Element {
    const { Component, pageProps, apollo, token, user } = this.props;
    const props = pageProps as PageProps;
    const actionsToCheck = props.permissions || [];
    const isAllowed = user && actionsToCheck.every(one => hasPermission(user.role, one));
    if (typeof token === 'undefined') {
      return (
        <ApolloProvider client={apollo}>
          <NotificationProvider>
            <Component {...pageProps} />
          </NotificationProvider>
        </ApolloProvider>
      );
    } else {
      return (
        <ApolloProvider client={apollo}>
          <UserProvider value={user}>
            <NotificationProvider>
              <NotificationContainer />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={theme}>
                  <ModalProvider>
                    {isAllowed ? <Component {...pageProps} user={user} /> : <NotFoundPage {...pageProps} />}
                  </ModalProvider>
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </NotificationProvider>
          </UserProvider>
        </ApolloProvider>
      );
    }
  }
}

export default withApollo(AyzerDental as never);
